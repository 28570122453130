export default {
  validationMessages: [],
  locations: [],
  providersForSelectedLocation: [],
  selectedLocation: {},
  selectedProvider: {},
  selectedProviderId: {},
  selectedAppointmentType: {},
  providers: [],
  providerLocationMappings: [],
  requestedDates: [],
  isSubmitted: false,
  appointmentTimeIntervals: [],
  appointmentTypeList: [],
  showAppointmentPickerModal: false,

  currentMonth: new Date().getMonth(),
  currentCalendarView: {
    view: 'work_week',
    startDate: new Date(),
    endDate: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 6)),
    minDate: new Date(),
    selectedSlot: {},
    availableSlots: [],
    selectedAppointmentTemplate: {},
  },
  search_available_appointment_form_errors: {},
  appointmentStatusList: [],
  appointmentSeriesTypeList: [],
  singleAppointmentSeriesDetailsList: [],
  appointmentSeries: {},
  doctorGroupId: 0,
  auth: {},
  selectedGroupPreferences: [],
  enableToScheduleAppointment: false,
  selectedCase: undefined,
  patientCases: [],
  isScheduling: false,
};
