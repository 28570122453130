import '@rxnt/icons';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './assets/scss/styles.scss';
import './index.css';

import * as serviceWorker from './serviceWorker';

import { createRoot } from 'react-dom/client';
import App from './app/app';
import './datadog-rum-init';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

serviceWorker.unregister();
